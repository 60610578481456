




















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import SeletorDeAtributo from '@/components/produto/SeletorDeAtributo.vue'
import DialogoDeEdicaoDeAtributo from '@/components/produto/DialogoDeEdicaoDeAtributo.vue'
import { FindAtributoUseCase } from '@/usecases/produto/FindAtributoUseCase'
import { Atributo, Page } from '@/models'
import { CancelToken } from 'axios'
import { Pageable } from '@/models/Pageable'

@Component({
	components: {
		TelaGenerica,
		DialogoDeEdicaoDeAtributo,
		DataTableDeCrudPaginado,
		SeletorDeAtributo,
	},
})

export default class TelaDeAtributos extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeAtributo
	
	findAtributoUseCase = new FindAtributoUseCase()
	atributos: Atributo[] = []
	busca = ''
	carregando = false
	editando = false

	atributo: Atributo | null = null
	
	headers = [
		{ text: 'Atributo', value: 'nome' },
	]

	abrirDialogoDeEditarMarca(indice: number) {
		this.editando = true
		this.atributo = this.atributos[indice]
		this.dialogoDeEdicao.mostrar()
	}

	abrirDialogoDeCriarMarca() {
		this.editando = false
		this.atributo = {id:"",nome:""}
		this.dialogoDeEdicao.mostrar()
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<Atributo>> {
		const pagina = await this.findAtributoUseCase.find({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.atributos = pagina.content
		return pagina
	}

	atributoSalvo(atributoSalvo: Atributo) {
		const indice = this.atributos.findIndex(marca => marca.id === atributoSalvo.id)
		
		if (indice === -1) {
			this.atributos.push(atributoSalvo)
		} else {
			this.atributos.splice(indice, 1, atributoSalvo)
		}
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
