














































































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { FindAtributoUseCase, SaveAtributoUseCase } from '@/usecases'
import { Atributo } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DataOptions } from 'vuetify'
import { Page } from '@/models'
import { Pageable } from '@/models/Pageable'
import axios, { CancelTokenSource } from 'axios'
import { ObserveVisibility } from 'vue-observe-visibility'

@Component({
	directives: {
		ObserveVisibility,
	},
})
export default class SeletorDeAtributo extends Vue {
	@PropSync('value', { type: [Object, Array], default: null }) atributo!: (string | Atributo) | (string | Atributo)[] | null
	@Prop({ type: Boolean }) podeCriar!: boolean
	@Prop({ type: Boolean }) podeEditar!: boolean
	@Prop({ type: Boolean }) returnObject!: boolean
	@Prop({ type: Boolean }) multiple!: boolean
	@Prop({ type: Array, default: () => [] }) ignores?: (Atributo | null)[]
	busca = ''
	itemEditando: null | ItemSelecao = null
	itensBuscados: ItemSelecao[] = []
	findAtributoUseCase = new FindAtributoUseCase()
	saveAtributoUseCase = new SaveAtributoUseCase()

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	pagina: Page<Atributo> | null = null
	cancelToken: CancelTokenSource | null = null
	buscandoMaisItens = false
	buscandoNovosItens = true

	created() {
		this.buscarNovosItens()
	}

	atributoEmItemAutocompletar(atributo: Atributo | string): ItemSelecao {
		return typeof atributo === 'string'
			? {
				id: '',
				nome: atributo,
				tipo: 'selecao',
			}
			: {
				...atributo,
				tipo: 'selecao',
			}
	}

	get itens() {
		let itens: ItemAutocompletar[] = [];
		if(this.atributo !== null) {
			if(Array.isArray(this.atributo)) {
				itens = this.atributo.map(this.atributoEmItemAutocompletar)
			} else {
				itens = [this.atributoEmItemAutocompletar(this.atributo)]
			}
		}

		if(this.podeCriar) {
			const header: ItemHeader = {
				tipo: 'header',
				header: 'Selecione uma opção ou crie uma',
			}

			itens = [
				...itens,
				header,
			]	
		}

		itens = [
			...itens,
			...this.itensFiltrados,
		]
		
		if (this.pagina && !this.pagina.last && !this.buscandoNovosItens) {
			itens.push({ tipo: 'buscar-mais' })
		}

		const atributos = this.atributo

		if (!this.podeCriar || !this.busca || this.buscandoNovosItens) {
			return itens
		}
		if (!atributos) {
			return [{ tipo: 'novo' }, ...itens]
		}
		if (Array.isArray(atributos)) {
			const encontrouItemNaBusca = atributos.some(
				atributo => this.busca.toLowerCase() === this.obterNomeAtributo(atributo).toLowerCase(),
			)
			if(encontrouItemNaBusca) {
				return itens
			} 
			return [{ tipo: 'novo' }, ...itens]
		}

		if (this.busca.toLowerCase() === this.obterNomeAtributo(atributos).toLowerCase()) 
			return itens
		return [{ tipo: 'novo' }, ...itens]
	}

	get itensFiltrados() {
		const ignores = this.ignores
		if (!ignores) return this.itensBuscados
		return this.itensBuscados.filter(
			(atributo) => ignores.every(
				(atributoAExcluir) => atributoAExcluir?.id !== atributo.id,
			),
		)
	}

	obterNomeAtributo(atributo: Atributo | string) {
		return typeof atributo === 'string'
			? atributo
			: atributo.nome
	}

	async selecionaItem(item: ItemSelecao | ItemNovo) {
		if (!item) {
			this.$emit('input', null)
			return
		}

		if (item.tipo === 'novo') {
			await this.criarAtributo()
		} else {
			this.$emit('input', item)
		}
	}

	async criarAtributo() {
		try {
			if (this.busca.trim() == '') return AlertModule.setError("Não pode ser vazio")
			
			const atributoNova: Atributo = { id: '', nome: this.busca }
			const atributoCriada = await this.saveAtributoUseCase.create(atributoNova)
			const item: ItemSelecao = { ...atributoCriada, tipo: 'selecao' }
			this.itensBuscados.push(item)
			this.emitirInput(item)
			return atributoCriada
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	emitirInput(item: ItemSelecao) {
		if (!Array.isArray(this.atributo)) {
			this.$emit('input', this.returnObject
				? item
				: item.id,
			)
		} else {
			const atributos = this.atributo || []
			this.$emit('input', [
				...atributos,
				item,
			])
		}
	}

	editar(item) {
		this.itemEditando = { ...item }
	}

	async confirmarEdicao() {
		if (!this.itemEditando) {
			return
		}
		if (!this.itemEditando.nome) {
			this.itemEditando = null
			return
		}
		const indice = this.itensBuscados.findIndex(
			item => item.id === this.itemEditando?.id,
		)
		if (indice === -1) return

		this.itensBuscados.splice(indice, 1, this.itemEditando)
		const atributoEditada = {
			id: this.itemEditando.id,
			nome: this.itemEditando.nome,
		}
		await this.saveAtributoUseCase.update(atributoEditada)
		this.itemEditando = null
	}

	async buscar(paginavel: Pageable = {}): Promise<Atributo[]> {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findAtributoUseCase.find({
				busca: this.busca || undefined,
				page: paginavel.page || 0,
				size: paginavel.size || 10,
				sort: 'nome',
			}, axiosConfig);
			this.paginacao.page = this.pagina.number
			return this.pagina.content
		} catch (e) {
			if (axios.isCancel(e)) throw e
			AlertModule.setError(e)
			throw e
		}
	}

	async buscarNovosItens() {
		this.buscandoNovosItens = true
		const atributos = await this.buscar({
			...this.paginacao,
			page: 0,
		})
		this.itensBuscados = []
		this.inserirAtributos(atributos)
		this.buscandoNovosItens = false
	}

	async buscarMaisItens() {
		this.buscandoMaisItens = true
		const atributos = await this.buscar({
			...this.paginacao,
			page: this.paginacao.page + 1,
		})
		this.inserirAtributos(atributos)
		this.buscandoMaisItens = false
	}

	inserirAtributos(atributos: Atributo[]) {
		this.itensBuscados = [
			...this.itensBuscados,
			...atributos.map<ItemSelecao>(item => ({
				...item,
				tipo: 'selecao',
			})),
		]
	}

	@Watch('busca')
	onChangeBusca(buscaAtual, buscaAnterior) {
		if (buscaAtual === buscaAnterior) return
		this.buscarNovosItens()
	}

	mudouVisibilidade(visivel) {
		if (visivel && !this.buscandoMaisItens) this.buscarMaisItens()
	}
}

export type ItemHeader = {
	tipo: 'header'
	header: string
}

export type ItemSelecao = Atributo & {
	tipo: 'selecao'
}

export type ItemNovo = {
	tipo: 'novo'
	text: string
}

export type ItemBuscarMais = {
	tipo: 'buscar-mais'
}

export type ItemAutocompletar = ItemHeader | ItemSelecao | ItemNovo | ItemBuscarMais
