





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveAtributoUseCase } from '@/usecases'
import type { Atributo } from '@/models'

@Component

export default class DialogoDeEdicaoDeAtributo extends Vue {
	@Prop({type: Object}) atributo?: Atributo 
	mostra = false
	salvando = false
	saveAtributoUseCase = new SaveAtributoUseCase()
	atributoEmEdicao: string | undefined = undefined

	mostrar() {
		this.mostra = true
	}

	esconder() {
		this.atributoEmEdicao = undefined
		this.mostra = false
	}

	salvar() {
		if (!this.atributo) return
		if (!this.atributoEmEdicao) return

		try {
			this.salvando = true
			this.editarAtributo()

			const atributoSalvo = this.atributo.id
				? this.saveAtributoUseCase.update(this.atributo)
				: this.saveAtributoUseCase.create(this.atributo)

			this.$emit('confirmado', atributoSalvo)
			this.mostra = false
			AlertModule.setSuccess('Atributo salva com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.atributoEmEdicao = undefined
			this.mostra = false
		}
	}

	editarAtributo() {
		if(this.atributo && this.atributoEmEdicao)
			this.atributo.nome = this.atributoEmEdicao
	}

	@Watch('atributo')
	onChangeAtributo() {
		this.atributoEmEdicao = this.atributo?.nome
	}

}
